import React from 'react';
import Layout from '../../components/common/Layout';
import Prices from '../../components/pricing/Prices';
import SEO from '../../components/SEO';

const PricesPage = () => (
  <Layout lang="en">
    <SEO
      title="Prices - Nextcloud Hosting"
      description="Overview of all our packages and prices"
    />
    <Prices lang="en" minWidth={1200} />
  </Layout>
);

export default PricesPage;
